<script>
    import { required, minLength, numeric } from "vuelidate/lib/validators";
    import Teams from '@/services/Teams'
    import Swal from "sweetalert2";
    //import Aws from '@/services/Aws'
    import validationMessages from '@/components/validations'

    export default {
        components:{
            validationMessages
        },
        props: {
            team: {
                type: String,
                default: null
            },
            squad_func: {
                type: Array,
                default: null
            },
        },
        data() {
            return {
                first_name: '',
                last_name: '',
                shirt_number: '',
                player_function: '',
                order: '',
                img: '',
                file: '',
                twitter: '',
                instagram: '',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                selected_func: null,
            };
        },
        validations: {
            first_name: {
                required,
                minLength: minLength(2)
            },
            last_name: {
                required,
                minLength: minLength(2)
            },
            order: {
                numeric,
            },
        },
        methods: {
            refreshData() {
                this.$emit('onRefresh') //event from parent
            },
            async addSquadPlayer() {
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {

                        let formData = new FormData()
                        formData.append('type', this.team)
                        formData.append('team', process.env.VUE_APP_LEAGUE_SHORT)
                        formData.append('source', 'Dashboard')
                        formData.append('first_name', this.first_name)
                        formData.append('last_name', this.last_name)
                        formData.append('function', this.player_function)
                        formData.append('file', this.file)
                        formData.append('shirt_number', this.shirt_number)
                        formData.append('instagram', this.instagram)
                        formData.append('twitter', this.twitter)

                        await Teams.addSquadPlayer(formData)
                        .then((response) => {
                            this.successmsg(response);
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                        })

                    } catch (error) {
                         this.error = error.response.data.error ? error.response.data.error : "";
                         this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;
            },

            selectFile(event){
                this.file = event.target.files[0]
                this.img = URL.createObjectURL(this.file)

                // const fileName = event.target.files[0].name
                // const file = event.target.files[0]
                // const aws_folder = process.env.VUE_APP_AWS_S3_IMG_FOLDER + 'squad/'
                // if(file) {
                //     Aws.singleUpload(process.env.VUE_APP_AWS_S3_BUCKET, aws_folder, fileName, file)
                //         .then((response) => {
                //             this.img = response.fullPath
                //         })
                // }
            },

            closeModal() {
                this.showModal = false;
                this.first_name= '',
                this.last_name= '',
                this.shirt_number= '',
                this.player_function= '',
                this.order= '',
                this.img= '',
                this.file= '',
                this.twitter= '',
                this.instagram= '',
                this.selected_func= null
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Change has been saved",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 5000,
                });
            },
        }
    };
</script>


<template>
    <b-modal v-model="showModal" id="squad_add" title="Add Squad Player" title-class="font-18" centered>

        <form @submit.prevent="addSquadPlayer">
            <b-form-group label-for="image" class="mb-3 text-center">
                <img :src="img != '' ? img : require('@/assets/images/image_not_found.png')" alt="" width="200" class="img-thumbnail me-2" >
                <b-form-file @change="selectFile" type="file" class="mt-3" plain accept="image/jpeg, image/png" ></b-form-file>
            </b-form-group>

            <b-form-group label="Firstname" label-for="formrow-firstname-input" class="mb-3">
                <b-form-input
                        v-model.trim="first_name"
                        type="text"
                        id="firstname"
                        aria-describedby="firstname-feedback"
                        :class="{
                          'is-invalid': submitted && $v.first_name.$error,
                        }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'First Name'" :validationName="$v.first_name"></validationMessages>
            </b-form-group>

            <b-form-group label="Lastname" label-for="formrow-lastname-input" class="mb-3">
                <b-form-input
                        v-model.trim="last_name"
                        type="text"
                        id="lastname"
                        aria-describedby="lastname-feedback"
                        :class="{
                          'is-invalid': submitted && $v.last_name.$error,
                        }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Last Name'" :validationName="$v.last_name"></validationMessages>
            </b-form-group>

            <b-form-group label="Function" label-for="formrow-function-input" class="mb-3">
                <select v-model="player_function" class="form-select">
                    <option v-for="sfunc in squad_func" :key="sfunc.function_name" :value="sfunc.function_name">{{sfunc.function_name}}</option>
                </select>
            </b-form-group>

            <b-form-group label="Shirt Number" label-for="formrow-shirt_number-input" class="mb-3">
                <b-form-input
                        v-model.trim="shirt_number"
                        type="text"
                        id="shirt_number"
                        aria-describedby="shirt_number-feedback"
                >
                </b-form-input>
            </b-form-group>

            <b-form-group label="Instagram" label-for="formrow-instagram-input" class="mb-3">
                <b-form-input
                        v-model.trim="instagram"
                        type="text"
                        id="instagram"
                        aria-describedby="twitter-feedback"
                >
                </b-form-input>
            </b-form-group>

            <b-form-group label="Twitter" label-for="formrow-twitter-input" class="mb-3">
                <b-form-input
                        v-model.trim="twitter"
                        type="text"
                        id="twitter"
                        aria-describedby="twitter-feedback"
                >
                </b-form-input>
            </b-form-group>

        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addSquadPlayer" :disabled="tryingToEdit">Change</b-button>
        </template>
    </b-modal>
</template>