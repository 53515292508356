<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import Teams from '@/services/Teams'
  import modalEditSquad from "@/components/modals/squad/modalEditSquad";
  import modalAddSquad from "@/components/modals/squad/modalAddSquad";
  import Swal from "sweetalert2";
  //import Sortable from 'sortablejs';


/*  const createSortable = (el, options) => {

    return Sortable.create(el, {
      ...options,
      onEnd: function (evt) {
        changeOrder(evt.oldIndex, evt.newIndex);

      },

    });
  };

  const changeOrder = (oldIndex, newIndex) => {

    var newPlayerId = parseInt(newIndex)+1;

    var movedEl = document.querySelectorAll(".squadTable table tbody tr:nth-child("+newPlayerId+")");
    var movedElplayerId = movedEl[0].cells[0].innerText;

    Teams.editSquadPlayer(movedElplayerId,{
      order: newPlayerId,
    }).then((response) => {
      console.log(response);
    }).catch(error => {
      console.log(error);
      //this.error = error.response.data.error ? error.response.data.error : "";
      //this.failedmsg(this.error)
    }).finally(() => {

    })

  };*/


export default {
  components: { Layout, PageHeader, modalEditSquad, modalAddSquad },
  directives: {
/*    sortable : {
      bind(el, binding) {
        const table = el;
        table._sortable = createSortable(table.querySelector(".squadTable tbody"), binding.value);
      },
    },*/
  },
  page: {
    title: "Team Squad",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Squad",
      items: [
        {
          text: "Teams",
          href: "/",
        },
        {
          text: "Squad",
          active: true,
        },
      ],
/*      sortableOptions: {
        chosenClass: 'is-selected'
      },*/
      isBusy: false,
      teamCompetitions: [],
      competitionTeams: [],
      selected: '',
      squadtableData: [],
      squadProvidertableData: [],
      squadProviderFiltered: [],
      totalRows: 1,
      totalRows_source: 1,
      currentPage: 1,
      currentPage_source: 1,
      perPage: 10,
      perPage_source: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filter_source: null,
      filterOn: [],
      filterOn_source: [],
      sortBy: "order",
      sortBy_source: "order",
      sortDesc: false,
      sortDesc_source: false,
      team_id: '',
      modalData:{},
      squad_functions:[],
      clickedAdd: false,
      validTeam: false,
      refreshDataVar: false,
      fields: [
        {
          key: "id",
          sortable: false,
          class: 'd-none'
        },
        {
          key: "index",
          label: "Order",
          sortable: true,
          class: 'text-center'
        },
        {
          key: "img",
          label: "Photo",
          sortable: false,
        },
        {
          key: "first_name",
          label: "First Name / Last Name",
          sortable: true,
        },
        {
          key: "function",
          label: "Function",
          sortable: false,
          class: 'text-capitalize'
        },
        {
          key: "shirt_number",
          label: "Shirt Number",
          sortable: false,
        },
        {
          key: "source_id",
          label: "Source ID",
          sortable: false,
        },
        {
          key: "source",
          label: "Source",
          sortable: false,
        },
        "action",
      ],
      fields_source: [
        {
          key: "first_name",
          label: "First Name / Last Name",
          sortable: true,
        },
        {
          key: "position",
          label: "Function",
          sortable: false,
          class: 'text-capitalize'
        },
        {
          key: "shirt_number",
          label: "Shirt Number",
          sortable: false,
        },
        "action",
      ]
    };
  },
  computed: {
      /**
       * Total no. of records
       */
      rows() {
        return this.squadtableData.length;
      },
      rows_source() {
        return this.squadProviderFiltered.length;
      },
  },
  /*watch:{
    refreshDataVar(oldVal, newVal){
      console.log(oldVal + newVal);
    }
  },*/
  mounted() {

    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  async created() {
    this.getTeamCompetitions()
  },
  methods:{

    fullname(firstname, lastname){
      return firstname + ' ' + lastname;
    },

    async getTeamCompetitions(){
      try {
        this.teamCompetitions = await Teams.getTeamCompetitions()

        if(this.teamCompetitions.data.competitions.length > 0){
          if(this.teamCompetitions.data.competitions[0].teams.length > 0){
            this.competitionTeams = this.teamCompetitions.data.competitions[0].teams
          }
        }

      }catch(error){
        this.error = error.response.data.error ? error.response.data.error : "";
      }
    },

    async getTeamSquad(event, refreshData = false){

      try {
        if(!refreshData){
          this.selected = event.target.value;
        }

        if(this.selected !== ''){
          this.currentPage = 1
          this.toggleBusy();
          const response = await Teams.getTeamSquad(this.selected)

          if(response.data.squad.length > 0){
            this.squadtableData = response.data.squad[0].players;
            this.squad_functions = response.data.squad_functions;
          }else{
            this.squadtableData = []
          }

          if(response.data.squad_provider.length > 0){

            this.squadProvidertableData = response.data.squad_provider
            this.squadProviderFiltered = this.squadProvidertableData.filter(function (players) {  return players.squad_imported === 0});
          }else{
            this.squadProviderFiltered = []
          }

          this.toggleBusy();
        }
      }catch(error){
        this.error = error.response.data.error ? error.response.data.error : "";
      }
    },

    async pullSquad(player_id){
      Swal.fire({
        title: "Move player to squad",
        text: "This Player will be moved in the squad!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Move it!",
      }).then((result) => {
        if (result.value) {
          try {
            Teams.pullSquad({
              player_id: player_id
            }).then((response) => {
              const res = response.data ? response.data : false;
              const error = response.data.error ? response.data.error : 'Failed';
              if(res){
                this.getTeamSquad('', true)
                Swal.fire("Moved to squad", "Player is successfully moved to squad!", "success");
              }else{
                Swal.fire("Fail!", error, "warning");
              }
            })

          }catch(error){
            this.error = error.response.data.error ? error.response.data.error : "";
          }
        }
      });

    },

    removeSquadPlayer(id){
      Swal.fire({
        title: "Remove player from squad?",
        text: "This Player will be removed from the squad!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Remove!",
      }).then((result) => {
        if (result.value) {
          try {
            Teams.removeSquadPlayer(id)
              .then((response) => {
                  const res = response.data ? response.data : false;
                  const error = response.data.error ? response.data.error : 'Failed';
                  if(res){
                    this.getTeamSquad('', true)
                    Swal.fire("Remove from squad", "Player is successfully removed from squad!", "success");
                  }else{
                    Swal.fire("Fail!", error, "warning");
                  }
              })

          }catch(error){
            this.error = error.response.data.error ? error.response.data.error : "";
          }
        }
      });
    },

    callModalSquadEdit(data) {
      this.$bvModal.show("squad_edit");
      this.modalData = data;
    },

    callModalSquadAdd(){
      this.clickedAdd = true;

      if(this.selected.length == 0){
        return;
      }
      this.validTeam = true;
      this.$bvModal.show("squad_add");
      this.team_id = this.selected;
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFiltered_source(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows_source = filteredItems.length;
      this.currentPage_source = 1;
    },
    
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">

            <div class="row mb-3">
              <label class="col-md-1 col-form-label">Select Team</label>
              <div class="col-md-3">
                <div class="form-row form-group mb-3">
                  <select class="form-select" :class="{'is-invalid': !validTeam && clickedAdd}" v-model="selected" @change="getTeamSquad($event)" id="selectTeam">
                    <option v-for="team in competitionTeams" :key="team.team_code" :value="team.team_code">{{team.team_name}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light" @click="callModalSquadAdd"><i class="mdi mdi-plus me-2"></i> Add Squad Member </a>
              </div>
            </div>

            <h4 class="card-title">Squad Members</h4>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                            v-model="filter"
                            type="search"
                            placeholder="Search..."
                            class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="table-responsive mb-0">
                  <b-table
                          :busy="isBusy"
                          :items="squadtableData"
                          :fields="fields"
                          responsive="sm"
                          :per-page="perPage"
                          :current-page="currentPage"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          show-empty
                          empty-text="No Data Found"
                          @filtered="onFiltered"
                          class="squadTable"
                  >

                    <template #cell(index)="squadtableData">
                      {{ squadtableData.item.order}}
                    </template>

                    <template v-slot:cell(first_name)="squadtableData">
                      <a>{{fullname(squadtableData.item.first_name, squadtableData.item.last_name)}}</a>
                    </template>

                    <template v-slot:cell(img)="squadtableData">
                      <img v-if="squadtableData.item.img" class="img-fluid" :src="squadtableData.item.img" width="100">
                      <img v-else class="img-fluid" src="@/assets/images/image_not_found.png" width="100">
                    </template>

                    <template v-slot:cell(action)="squadtableData">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <a href="javascript:void(0);" class="px-2 text-primary" v-b-tooltip.hover title="Edit" @click="callModalSquadEdit(squadtableData.item)">
                            <i class="uil uil-pen font-size-18"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover title="Delete" @click="removeSquadPlayer(squadtableData.item.id)">
                            <i class="uil uil-trash-alt font-size-18"></i>
                          </a>
                        </li>
                      </ul>
                    </template>

                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>

                  </b-table>

                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Available Data From Source</h4>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                            v-model="perPage_source"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                            v-model="filter_source"
                            type="search"
                            placeholder="Search..."
                            class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="table-responsive mb-0">
                  <b-table
                          :busy="isBusy"
                          :items="squadProviderFiltered"
                          :fields="fields_source"
                          responsive="sm"
                          :per-page="perPage_source"
                          :current-page="currentPage_source"
                          :sort-by.sync="sortBy_source"
                          :sort-desc.sync="sortDesc_source"
                          :filter="filter_source"
                          :filter-included-fields="filterOn_source"
                          show-empty
                          empty-text="No Data Found"
                          @filtered="onFiltered_source"
                  >

                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>

                    <template v-slot:cell(first_name)="squadProviderFiltered">
                      <a>{{fullname(squadProviderFiltered.item.first_name, squadProviderFiltered.item.last_name)}}</a>
                    </template>

                    <template v-slot:cell(action)="squadProviderFiltered">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <a href="javascript:void(0);" class="px-2 text-primary" v-b-tooltip.hover title="Import PLayer" @click="pullSquad(squadProviderFiltered.item.player_id)">
                            <i class="uil uil-upload font-size-18"></i>
                          </a>
                        </li>
                      </ul>
                    </template>

                  </b-table>

                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                            v-model="currentPage_source"
                            :total-rows="rows_source"
                            :per-page="perPage_source"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- MODALS -->
    <modalEditSquad :squadData="modalData" :squad_func="this.squad_functions" @onRefresh="getTeamSquad('', true)"></modalEditSquad>

    <modalAddSquad :team="team_id" :squad_func="this.squad_functions" @onRefresh="getTeamSquad('', true)"></modalAddSquad>
    <!-- END  MODALS -->

  </Layout>
</template>
