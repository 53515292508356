<script>
    import { required, minLength, numeric } from "vuelidate/lib/validators";
    import Teams from '@/services/Teams'
    import Swal from "sweetalert2";
    //import Aws from '@/services/Aws'
    import validationMessages from '@/components/validations'

    export default {
        components:{
            validationMessages
        },
        props: {
            squadData: {
                type: Object,
                default: null
            },
            squad_func: {
                type: Array,
                default: null
            },
        },
        watch: {
            squadData: function(newVal) {
                this.id_e = newVal.id;
                this.first_name_e = newVal.first_name;
                this.last_name_e = newVal.last_name;
                this.shirt_number_e = newVal.shirt_number;
                this.img_e = newVal.img;
                this.order_e = newVal.order;
                this.function_e = newVal.function;
                this.instagram_e = newVal.instagram;
                this.twitter_e = newVal.twitter;
            }
        },
        data() {
            return {
                id_e: '',
                first_name_e: '',
                last_name_e: '',
                shirt_number_e: '',
                function_e: '',
                order_e: '',
                img_e: '',
                file_e: '',
                twitter_e: '',
                instagram_e: '',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                selected_func: null,
            };
        },
        validations: {
            first_name_e: {
                required,
                minLength: minLength(2)
            },
            last_name_e: {
                required,
                minLength: minLength(2)
            },
            order_e: {
                numeric,
            },
        },
        methods: {
            refreshData() {
                this.$emit('onRefresh') //event from parent
            },
            async editSquadPlayer() {
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {
                        
                        let formData = new FormData()
                        formData.append('first_name', this.first_name_e)
                        formData.append('last_name', this.last_name_e)
                        formData.append('function', this.function_e)
                        formData.append('file', this.file_e)
                        formData.append('shirt_number', this.shirt_number_e)
                        formData.append('order', this.order_e)
                        formData.append('instagram', this.instagram_e)
                        formData.append('twitter', this.twitter_e)
                        formData.append('_method', 'PATCH')

                        await Teams.editSquadPlayer(this.id_e, formData)
                        .then((response) => {
                            this.successmsg(response);
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                        })

                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;
            },

            selectFile(event){
                this.file_e = event.target.files[0]
                this.img_e = URL.createObjectURL(this.file_e)

                // const fileName = event.target.files[0].name
                // const file = event.target.files[0]
                // const aws_folder = process.env.VUE_APP_AWS_S3_IMG_FOLDER + 'squad/'
                // if(file) {
                //     Aws.singleUpload(process.env.VUE_APP_AWS_S3_BUCKET, aws_folder, fileName, file)
                //         .then((response) => {
                //             this.img_e = response.fullPath
                //         })
                // }
            },

            closeModal() {
                this.showModal = false;
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Change has been saved",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 5000,
                });
            },
        }
    };
</script>


<template>
    <b-modal v-model="showModal" id="squad_edit" title="Edit Squad Player" title-class="font-18" centered>

        <form @submit.prevent="editSquadPlayer">
            <b-form-group label-for="image" class="mb-3 text-center">
                <img :src="img_e != '' ? img_e : require('@/assets/images/image_not_found.png')" alt="" width="200" class="img-thumbnail me-2" >
                <b-form-file @change="selectFile" type="file" class="mt-3" plain accept="image/jpeg, image/png" ></b-form-file>
            </b-form-group>

            <b-form-group label="Firstname" label-for="formrow-firstname-input" class="mb-3">
                <b-form-input
                        v-model.trim="first_name_e"
                        type="text"
                        id="firstname"
                        aria-describedby="firstname-feedback"
                        :class="{
                          'is-invalid': submitted && $v.first_name_e.$error,
                        }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'First Name'" :validationName="$v.first_name_e"></validationMessages>
            </b-form-group>

            <b-form-group label="Lastname" label-for="formrow-lastname-input" class="mb-3">
                <b-form-input
                        v-model.trim="last_name_e"
                        type="text"
                        id="lastname"
                        aria-describedby="lastname-feedback"
                        :class="{
                          'is-invalid': submitted && $v.last_name_e.$error,
                        }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Last Name'" :validationName="$v.last_name_e"></validationMessages>
            </b-form-group>

            <b-form-group label="Function" label-for="formrow-function-input" class="mb-3">
                <select v-model="function_e" class="form-select">
                    <option v-for="sfunc in squad_func" :key="sfunc.function_name" :value="sfunc.function_name">{{sfunc.function_name}}</option>
                </select>
            </b-form-group>

            <b-form-group label="Shirt Number" label-for="formrow-shirt_number-input" class="mb-3">
                <b-form-input
                        v-model.trim="shirt_number_e"
                        type="text"
                        id="shirt_number"
                        aria-describedby="shirt_number-feedback"
                >
                </b-form-input>
            </b-form-group>

            <b-form-group label="Order" label-for="formrow-order-input" class="mb-3">
                <b-form-input
                        v-model.trim="order_e"
                        type="number"
                        min="1"
                        id="order"
                        aria-describedby="order-feedback"
                        :class="{
                          'is-invalid': submitted && $v.order_e.$error,
                        }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Order'" :validationName="$v.order_e"></validationMessages>
            </b-form-group>

            <b-form-group label="Instagram" label-for="formrow-instagram-input" class="mb-3">
                <b-form-input
                        v-model.trim="instagram_e"
                        type="text"
                        id="instagram"
                        aria-describedby="twitter-feedback"
                >
                </b-form-input>
            </b-form-group>

            <b-form-group label="Twitter" label-for="formrow-twitter-input" class="mb-3">
                <b-form-input
                        v-model.trim="twitter_e"
                        type="text"
                        id="twitter"
                        aria-describedby="twitter-feedback"
                >
                </b-form-input>
            </b-form-group>

        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="editSquadPlayer" :disabled="tryingToEdit">Change</b-button>
        </template>
    </b-modal>
</template>